export function isMobile() {
  const reg = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return reg.test(navigator.userAgent);
}
export function typeOf(obj) {
  const toString = Object.prototype.toString;
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object',
    '[object Function]': 'function',
    '[object AsyncFunction]': 'function'
  };
  return map[toString.call(obj)];
}
export function jpy2Other(v, rate) {
  return parseFloat((v * rate).toFixed(2));
}
export function other2Jpy(v, rate) {
  return parseFloat((v / rate).toFixed(0));
}