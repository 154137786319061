var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.url ? _c("div", [_vm.buttonText ? _c("el-button", {
    staticClass: "label",
    attrs: {
      size: _vm.size,
      type: "primary",
      plain: true
    },
    on: {
      click: _vm.open
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]) : _vm._e(), _c("w-dialog", {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      title: _vm.title,
      width: "800px",
      id: "printTest"
    },
    on: {
      onscroll: _vm.handleScroll
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c("el-button", {
          attrs: {
            size: _vm.size
          },
          on: {
            click: function ($event) {
              _vm.visible = false;
            }
          }
        }, [_vm._v("关闭")]), _vm.hasDown ? _c("el-button", {
          attrs: {
            size: _vm.size
          },
          on: {
            click: _vm.handlePrintOrDownload
          }
        }, [_vm._v(" 下载/打印 ")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 919977054),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_vm.fileType === "img" ? _c("img", {
    staticClass: "imageBox",
    attrs: {
      src: _vm.url
    }
  }) : _vm.fileType === "pdf" ? _vm._l(_vm.pdfTotalPages, function (i) {
    return _c("pdf", _vm._g(_vm._b({
      key: i,
      ref: "pdfComp",
      refInFor: true,
      attrs: {
        src: _vm.pdfSrc,
        page: i
      }
    }, "pdf", _vm.$attrs, false), _vm.$listeners));
  }) : _vm._e()], 2)], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };