var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "w100"
  }, [_c("el-select", _vm._g(_vm._b({
    class: _vm.quickAdd ? "w80" : "w100",
    attrs: {
      size: _vm.$attrs.size || _vm.size
    },
    scopedSlots: _vm._u([{
      key: "prefix",
      fn: function (props) {
        return [_vm._t("prefix", null, null, props)];
      }
    }, {
      key: "empty",
      fn: function (props) {
        return [_vm._t("empty", null, null, props)];
      }
    }], null, true),
    model: {
      value: _vm.selectValue,
      callback: function ($$v) {
        _vm.selectValue = $$v;
      },
      expression: "selectValue"
    }
  }, "el-select", {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), _vm.$listeners), [_vm._t("default", function () {
    return _vm._l(_vm.data, function (item, index) {
      return _c("el-option", _vm._b({
        key: index,
        class: {
          red: item.plain
        },
        attrs: {
          title: item.label
        }
      }, "el-option", item, false), [_vm._v(" " + _vm._s(_vm.ellipsisStr(item.label, 40)) + " ")]);
    });
  })], 2), _vm.quickAdd ? _c("el-button", {
    attrs: {
      size: _vm.$attrs.size || _vm.size,
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.quickAdd
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };