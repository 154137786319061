var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-input", _vm._g(_vm._b({
    ref: "input",
    class: {
      "limit-input": _vm.isLimitInput
    },
    attrs: {
      size: _vm.$attrs.size || _vm.wSize,
      showWordLimit: _vm.showWordLimit_,
      maxlength: _vm.maxlength_,
      autosize: _vm.autosize_
    },
    scopedSlots: _vm._u([_vm.prepend && !_vm.$slots.prepend ? {
      key: "prepend",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.prepend) + " ")];
      },
      proxy: true
    } : null, _vm.append && !_vm.$slots.append ? {
      key: "append",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.append) + " ")];
      },
      proxy: true
    } : null, _vm._l(_vm.$slots, function (_slot, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true),
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }, "el-input", {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), _vm.$listeners));
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };