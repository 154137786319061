var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "o-y-a",
    staticStyle: {
      "max-height": "300px"
    }
  }, [_vm.isTags && _vm.nodes.children.length ? _c("div", {
    staticClass: "tags-box component-scrollbar"
  }, _vm._l(_vm.nodes.children, function (item, index) {
    return _c("el-tag", {
      key: item.value,
      staticClass: "tag",
      attrs: {
        title: item.label,
        size: _vm.size,
        closable: true
      },
      on: {
        close: function ($event) {
          return _vm.onClose(item.value);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.isTags === true ? item.label : _vm.ellipsisStr(item.label, _vm.isTags)) + " ")]);
  }), 1) : _vm._e(), _vm.isFilter_ ? _c("div", {
    staticClass: "input-box"
  }, [_c("w-input", {
    attrs: {
      clearable: true,
      placeholder: "请输入关键字过滤"
    },
    model: {
      value: _vm.filterText,
      callback: function ($$v) {
        _vm.filterText = $$v;
      },
      expression: "filterText"
    }
  }), _c("el-button", {
    staticClass: "clear-all",
    attrs: {
      size: _vm.size,
      type: "text"
    },
    on: {
      click: _vm.onClear
    }
  }, [_c("i", {
    staticClass: "el-icon-close"
  })])], 1) : _vm._e(), _c("el-tree", _vm._g(_vm._b({
    ref: "tree",
    staticClass: "component-scrollbar",
    attrs: {
      data: _vm.data_,
      "show-checkbox": true,
      checkStrictly: _vm.checkStrictly,
      "filter-node-method": _vm.filterNodeMethod
    },
    on: {
      check: _vm.onCheck
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        node,
        data
      }) {
        return [_c("div", {
          class: {
            ellipsis: true,
            red: data.plain
          },
          attrs: {
            title: node.label
          }
        }, [_vm._v(" " + _vm._s(node.label) + " ")])];
      }
    }])
  }, "el-tree", {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), _vm.$listeners))], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };