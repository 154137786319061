var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-dialog", {
    staticClass: "w-dialog",
    attrs: {
      width: "70%",
      title: "导入"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c("el-button", {
          attrs: {
            size: _vm.$store.getters["size"]
          },
          on: {
            click: function ($event) {
              _vm.visible = false;
            }
          }
        }, [_vm._v(" 取消 ")]), _c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.$store.getters["size"],
            disabled: !_vm.fileList.length
          },
          on: {
            click: _vm.confirm
          }
        }, [_vm._v(" 导入 ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_vm._t("importForm"), _c("div", {
    staticClass: "steps"
  }, [_vm._v(" 第一步: "), _vm.template ? _c("el-button", {
    staticStyle: {
      "text-decoration": "underline"
    },
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v("下载模板")]) : _c("el-button", {
    staticStyle: {
      color: "#606266"
    },
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v(" 暂时没有模板")])], 1), _c("div", [_vm._v("第二步: 上传文件")]), _c("w-upload", _vm._b({
    ref: "upload",
    attrs: {
      action: _vm.action,
      timeout: _vm.timeout,
      data: _vm.uploadData,
      drag: true,
      multiple: true,
      autoUpload: false,
      limit: 100,
      "on-change": _vm.onChange,
      "before-remove": _vm.beforeRemove
    },
    on: {
      progress: _vm.onProgress
    },
    model: {
      value: _vm.fileList,
      callback: function ($$v) {
        _vm.fileList = $$v;
      },
      expression: "fileList"
    }
  }, "w-upload", {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), [_c("i", {
    staticClass: "el-icon-upload"
  }), _c("div", {
    staticClass: "el-upload__text"
  }, [_vm._v("文件大小不超过20M")])]), _c("w-form", {
    ref: "wForm",
    attrs: {
      form: _vm.form,
      labelWidth: "180px"
    }
  }), _c("el-progress", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      percentage: _vm.percentage,
      "stroke-width": 14,
      "text-inside": ""
    }
  }), _c("span", {
    staticClass: "progressTip",
    domProps: {
      innerHTML: _vm._s(_vm.progressTip)
    }
  }), [_c("div", {
    staticClass: "assert"
  }, [_vm._t("assert", function () {
    return [_c("div", [_vm._v("导入声明：")]), _c("div", [_vm._v("请下载模板后,按照模板中的单元格备注提示，填写内容，并上传。")])];
  })], 2)]], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };