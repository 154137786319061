var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-date-picker", _vm._b({
    ref: "daterange",
    class: _vm.searchType,
    attrs: {
      type: _vm.searchType,
      popperClass: `y-header-search-${_vm.searchType}`,
      "start-placeholder": "开始",
      "end-placeholder": "结束"
    },
    on: {
      change: _vm.onSearch,
      focus: function ($event) {
        return _vm.onChangeFocus($event, true);
      },
      blur: function ($event) {
        return _vm.onChangeFocus($event);
      }
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, "w-date-picker", _vm.searchOption, false));
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };