var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-popover", {
    ref: "popover",
    attrs: {
      width: _vm.width,
      disabled: _vm.disabled
    },
    on: {
      show: _vm.onShow,
      hide: _vm.onHide
    }
  }, [_c("div", {
    ref: "treeTag",
    staticClass: "input-box",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("div", {
    ref: "tagsBox",
    staticClass: "tags-box component-scrollbar"
  }, _vm._l(_vm.tagsList, function (item, index) {
    return _c("el-tag", {
      key: index,
      class: {
        tag: true,
        "disabled-tag": _vm.disabled
      },
      attrs: {
        size: _vm.size,
        closable: !_vm.disabled,
        "disable-transitions": true,
        title: item.label,
        type: _vm.disabled ? "info" : ""
      },
      on: {
        close: function ($event) {
          return _vm.toClose(item.value);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.ellipsisStr(item.label, _vm.tagNum)) + " ")]);
  }), 1), _c("w-input", {
    staticClass: "input",
    style: _vm.jsStyle,
    attrs: {
      disabled: _vm.disabled,
      placeholder: _vm.placeholder_
    },
    on: {
      blur: _vm.onBlur
    },
    scopedSlots: _vm._u([{
      key: "suffix",
      fn: function () {
        return [_c("i", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.isHover,
            expression: "!isHover"
          }],
          staticClass: "el-icon-arrow-down",
          style: _vm.reicon,
          on: {
            mouseenter: function ($event) {
              return _vm.toggleIcon(true);
            }
          }
        }), _c("i", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.isHover,
            expression: "isHover"
          }],
          staticClass: "el-icon-circle-close",
          on: {
            mouseleave: function ($event) {
              return _vm.toggleIcon(false);
            },
            click: function ($event) {
              _vm.value = [];
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterText,
      callback: function ($$v) {
        _vm.filterText = $$v;
      },
      expression: "filterText"
    }
  })], 1), _c("w-tree", _vm._g(_vm._b({
    ref: "tree",
    staticClass: "input-tree component-scrollbar",
    attrs: {
      isTags: false,
      isFilter: false
    },
    on: {
      check: _vm.onCheck,
      nodeChange: _vm.onNodeChange
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, "w-tree", {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), _vm.$listeners))], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };