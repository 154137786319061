import kv from './components/kv';
export default {
  name: 'wConfig',
  props: {
    action: {
      type: String,
      default: ''
    }
  },
  components: {
    kv
  },
  data() {
    return {
      activeName: '0',
      activeConfig: {
        key: undefined,
        type: 'kv',
        title: undefined
      },
      configs: []
    };
  },
  mounted() {
    this.$w_fun.post(this.action).then(res => {
      this.configs = res.data.list;
      this.$nextTick(() => {
        this.$refs['menu' + this.configs[0].list[0].key][0].$el.click();
      });
    });
  },
  methods: {
    handleOpen(a, b) {},
    changeMenu(menu) {
      this.activeConfig = menu;
    },
    handleClick() {
      this.$nextTick(_ => {
        for (const item in this.configs) {
          if (item === this.activeName) {
            this.$refs['menu' + this.configs[item].list[0].key][0].$el.click();
          }
        }
      });
    },
    save() {
      this.$w_fun.post('/setting/save', {
        key: this.activeConfig.key,
        value: this.$refs.config[0].getValue()
      }).then(res => {
        this.$refs.config[0].getDetail();
      });
    }
  }
};