export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      logo: require('@/assets/image/logo.png') //logoImg
    };
  },

  computed: {
    title() {
      return this.SetConfig.title;
    }
  }
};