import { debounce, throttle } from 'throttle-debounce';
import { addResizeListener, removeResizeListener } from 'element-ui/src/utils/resize-event';
import Mousewheel from 'element-ui/src/directives/mousewheel';
import Locale from 'element-ui/src/mixins/locale';
import Migrating from 'element-ui/src/mixins/migrating';
import { createStore, mapStates } from './store/helper';
import TableLayout from './table-layout';
import TableBody from './table-body';
import TableHeader from './table-header';
import TableFooter from './table-footer';
import { parseHeight } from './util';
let tableIdSeed = 1;
const ScrollbarMap = {
  horizontal: {
    type: ['width', 'Width'],
    axle: ['left', 'right', 'Left', 'Right'],
    client: 'X'
  },
  vertical: {
    type: ['height', 'Height'],
    axle: ['top', 'bottom', 'Top', 'Bottom'],
    client: 'Y'
  }
};
export default {
  name: 'ElTable',
  mixins: [Locale, Migrating],
  directives: {
    Mousewheel
  },
  props: {
    data: {
      type: Array,
      default: function () {
        return [];
      }
    },
    size: String,
    width: [String, Number],
    height: [String, Number],
    maxHeight: [String, Number],
    fit: {
      type: Boolean,
      default: true
    },
    stripe: Boolean,
    border: Boolean,
    rowKey: [String, Function],
    context: {},
    showHeader: {
      type: Boolean,
      default: true
    },
    showSummary: Boolean,
    sumText: {
      type: String,
      default: ''
    },
    summaryMethod: Function,
    rowClassName: [String, Function],
    rowStyle: [Object, Function],
    cellClassName: [String, Function],
    cellStyle: [Object, Function],
    headerRowClassName: [String, Function],
    headerRowStyle: [Object, Function],
    headerCellClassName: [String, Function],
    headerCellStyle: [Object, Function],
    highlightCurrentRow: Boolean,
    currentRowKey: [String, Number],
    emptyText: {
      type: String,
      default: '暂无数据'
    },
    expandRowKeys: Array,
    defaultExpandAll: Boolean,
    defaultSort: Object,
    tooltipEffect: String,
    spanMethod: Function,
    selectOnIndeterminate: {
      type: Boolean,
      default: true
    },
    indent: {
      type: Number,
      default: 16
    },
    treeProps: {
      type: Object,
      default() {
        return {
          hasChildren: 'hasChildren',
          children: 'children'
        };
      }
    },
    lazy: Boolean,
    load: Function
  },
  components: {
    TableHeader,
    TableFooter,
    TableBody
  },
  methods: {
    handleScroll() {
      const {
        bodyReallyWidth,
        bodyReallyHeight
      } = this.layout;
      const {
        scrollLeft,
        scrollTop
      } = this.bodyWrapper;
      const {
        horizontalTrack,
        horizontalThumb,
        verticalTrack,
        verticalThumb
      } = this.$refs;
      if (horizontalTrack && horizontalThumb) {
        const left = scrollLeft / bodyReallyWidth * horizontalTrack.getBoundingClientRect().width;
        horizontalThumb.style.transform = `translateX(${left}px)`;
      }
      if (verticalTrack && verticalThumb) {
        const top = scrollTop / bodyReallyHeight * verticalTrack.getBoundingClientRect().height;
        verticalThumb.style.transform = `translateY(${top}px)`;
      }
    },
    clickTrackHandler(e, direction) {
      const {
        type,
        axle,
        client
      } = ScrollbarMap[direction];
      const position = e.target.getBoundingClientRect();
      const thumb = this[direction + 'Long'];
      const long = position[type[0]]; // 滚动槽实际长度
      const maxOffset = long - thumb; // 最大偏移距离
      let offset = e['client' + client] - position[axle[0]] - thumb / 2; // 实际偏移距离
      if (offset < 0) {
        offset = 0;
      } else if (offset >= maxOffset) {
        offset = maxOffset;
      }
      this.$refs[direction + 'Thumb'].style.transform = `translate${client}(${offset}px)`;
      this.bodyWrapper['scroll' + axle[2]] = offset / long * this.layout['bodyReally' + type[1]];
    },
    clickThumbHandler(e, direction) {
      e.stopPropagation();
      if (e.ctrlKey || [1, 2].includes(e.button)) return;
      this.direction = direction;
      window.getSelection() && window.getSelection().removeAllRanges();
      this.startDrag(e);
      const el = e.currentTarget;
      if (!el) return;
      const {
        type,
        axle,
        client
      } = ScrollbarMap[direction];
      const trackLong = this.$refs[direction + 'Track'].getBoundingClientRect()[type[0]];
      const thumbLong = this[this.direction + 'Long'];
      this.thumbState = {
        trackLong,
        // 滚动槽长度
        thumbLong,
        // 滚动条长度
        thumbPosition: e['client' + client] - el.getBoundingClientRect()[axle[0]],
        // 鼠标点击滚动条的位置
        maxOffset: trackLong - thumbLong // 最大偏移距离
      };
    },

    startDrag(e) {
      e.stopImmediatePropagation();
      this.cursorDown = true;
      document.addEventListener('mousemove', this.mouseMoveDocumentHandler);
      document.addEventListener('mouseup', this.mouseUpDocumentHandler);
      this.originalOnSelectStart = document.onselectstart;
      document.onselectstart = () => false;
    },
    mouseMoveDocumentHandler: throttle(16, function (e) {
      if (this.cursorDown === false) return;
      const {
        trackLong,
        thumbPosition,
        maxOffset
      } = this.thumbState;
      if (!thumbPosition || !maxOffset) return;
      const {
        type,
        axle,
        client
      } = ScrollbarMap[this.direction];
      let offset = e['client' + client] - this.$refs[this.direction + 'Track'].getBoundingClientRect()[axle[0]] - thumbPosition;
      if (offset < 0) {
        offset = 0;
      } else if (offset >= maxOffset) {
        offset = maxOffset;
      }
      this.$refs[this.direction + 'Thumb'].style.transform = `translate${client}(${offset}px)`;
      this.bodyWrapper['scroll' + axle[2]] = offset / trackLong * this.layout['bodyReally' + type[1]];
    }),
    mouseUpDocumentHandler() {
      this.cursorDown = false;
      this.thumbState = {};
      document.removeEventListener('mousemove', this.mouseMoveDocumentHandler);
      document.removeEventListener('mouseup', this.mouseUpDocumentHandler);
      this.restoreOnselectstart();
    },
    restoreOnselectstart() {
      if (document.onselectstart !== this.originalOnSelectStart) document.onselectstart = this.originalOnSelectStart;
    },
    getMigratingConfig() {
      return {
        events: {
          expand: 'expand is renamed to expand-change'
        }
      };
    },
    setCurrentRow(row) {
      this.store.commit('setCurrentRow', row);
    },
    toggleRowSelection(row, selected) {
      this.store.toggleRowSelection(row, selected, false);
      this.store.updateAllSelected();
    },
    toggleRowExpansion(row, expanded) {
      this.store.toggleRowExpansionAdapter(row, expanded);
    },
    clearSelection() {
      this.store.clearSelection();
    },
    clearFilter(columnKeys) {
      this.store.clearFilter(columnKeys);
    },
    clearSort() {
      this.store.clearSort();
    },
    handleMouseLeave() {
      this.store.commit('setHoverRow', null);
      if (this.hoverState) this.hoverState = null;
    },
    updateScrollY() {
      const changed = this.layout.updateScrollY();
      if (changed) {
        this.layout.notifyObservers('scrollable');
        this.layout.updateColumnsWidth();
      }
    },
    handleFixedMousewheel(event, data) {
      const bodyWrapper = this.bodyWrapper;
      if (Math.abs(data.spinY) > 0) {
        const currentScrollTop = bodyWrapper.scrollTop;
        if (data.pixelY < 0 && currentScrollTop !== 0) {
          event.preventDefault();
        }
        if (data.pixelY > 0 && bodyWrapper.scrollHeight - bodyWrapper.clientHeight > currentScrollTop) {
          event.preventDefault();
        }
        bodyWrapper.scrollTop += Math.ceil(data.pixelY / 5);
      } else {
        bodyWrapper.scrollLeft += Math.ceil(data.pixelX / 5);
      }
    },
    handleHeaderFooterMousewheel(event, data) {
      const {
        pixelX,
        pixelY
      } = data;
      if (Math.abs(pixelX) >= Math.abs(pixelY)) {
        this.bodyWrapper.scrollLeft += data.pixelX / 5;
      }
    },
    // TODO 使用 CSS transform
    syncPostion() {
      const {
        scrollLeft,
        scrollTop,
        offsetWidth,
        scrollWidth
      } = this.bodyWrapper;
      const {
        headerWrapper,
        footerWrapper,
        fixedBodyWrapper,
        rightFixedBodyWrapper
      } = this.$refs;
      if (headerWrapper) headerWrapper.scrollLeft = scrollLeft;
      if (footerWrapper) footerWrapper.scrollLeft = scrollLeft;
      if (fixedBodyWrapper) fixedBodyWrapper.scrollTop = scrollTop;
      if (rightFixedBodyWrapper) rightFixedBodyWrapper.scrollTop = scrollTop;
      const maxScrollLeftPosition = scrollWidth - offsetWidth - 1;
      if (scrollLeft >= maxScrollLeftPosition) {
        this.scrollPosition = 'right';
      } else if (scrollLeft === 0) {
        this.scrollPosition = 'left';
      } else {
        this.scrollPosition = 'middle';
      }
    },
    throttleSyncPostion: throttle(16, function () {
      this.syncPostion();
    }),
    onScroll(evt) {
      const raf = window.requestAnimationFrame;
      if (!raf) {
        this.throttleSyncPostion();
      } else {
        raf(this.syncPostion);
      }
    },
    bindEvents() {
      this.bodyWrapper.addEventListener('scroll', this.onScroll, {
        passive: true
      });
      if (this.fit) {
        addResizeListener(this.$el, this.resizeListener);
      }
    },
    unbindEvents() {
      this.bodyWrapper.removeEventListener('scroll', this.onScroll, {
        passive: true
      });
      if (this.fit) {
        removeResizeListener(this.$el, this.resizeListener);
      }
    },
    resizeListener() {
      if (!this.$ready) return;
      let shouldUpdateLayout = false;
      const el = this.$el;
      const {
        width: oldWidth,
        height: oldHeight
      } = this.resizeState;
      const width = el.offsetWidth;
      if (oldWidth !== width) {
        shouldUpdateLayout = true;
      }
      const height = el.offsetHeight;
      if ((this.height || this.shouldUpdateHeight) && oldHeight !== height) {
        shouldUpdateLayout = true;
      }
      if (shouldUpdateLayout) {
        this.resizeState.width = width;
        this.resizeState.height = height;
        this.doLayout();
      }
    },
    doLayout() {
      if (this.shouldUpdateHeight) {
        this.layout.updateElsHeight();
      }
      this.layout.updateColumnsWidth();
    },
    sort(prop, order) {
      this.store.commit('sort', {
        prop,
        order
      });
    },
    toggleAllSelection() {
      this.store.commit('toggleAllSelection');
    }
  },
  computed: {
    tableSize() {
      return this.size || (this.$ELEMENT || {}).size;
    },
    bodyWrapper() {
      return this.$refs.bodyWrapper;
    },
    shouldUpdateHeight() {
      return this.height || this.maxHeight || this.fixedColumns.length > 0 || this.rightFixedColumns.length > 0;
    },
    bodyWidth() {
      const {
        bodyWidth
      } = this.layout;
      return bodyWidth + 'px';
    },
    bodyHeight() {
      const {
        headerHeight = 0,
        bodyHeight,
        footerHeight = 0
      } = this.layout;
      if (this.height) {
        return {
          height: bodyHeight ? bodyHeight + 'px' : ''
        };
      } else if (this.maxHeight) {
        const maxHeight = parseHeight(this.maxHeight);
        if (typeof maxHeight === 'number') {
          return {
            'max-height': maxHeight - footerHeight - (this.showHeader ? headerHeight : 0) + 'px'
          };
        }
      }
      return {};
    },
    fixedBodyHeight() {
      if (this.height) {
        return {
          height: this.layout.fixedBodyHeight ? this.layout.fixedBodyHeight + 'px' : ''
        };
      } else if (this.maxHeight) {
        let maxHeight = parseHeight(this.maxHeight);
        if (typeof maxHeight === 'number') {
          if (this.showHeader) {
            maxHeight -= this.layout.headerHeight;
          }
          maxHeight -= this.layout.footerHeight;
          return {
            'max-height': maxHeight + 'px'
          };
        }
      }
      return {};
    },
    fixedHeight() {
      if (this.maxHeight) {
        if (this.showSummary) {
          return {
            bottom: 0
          };
        }
        return {
          bottom: ''
        };
      } else {
        if (this.showSummary) {
          return {
            height: this.layout.tableHeight ? this.layout.tableHeight + 'px' : ''
          };
        }
        return {
          height: this.layout.viewportHeight ? this.layout.viewportHeight + 'px' : ''
        };
      }
    },
    emptyBlockStyle() {
      if (this.data && this.data.length) return null;
      let height = '100%';
      if (this.layout.appendHeight) {
        height = `calc(100% - ${this.layout.appendHeight}px)`;
      }
      return {
        width: this.bodyWidth,
        height
      };
    },
    horizontalLong() {
      const {
        bodyShowWidth,
        bodyReallyWidth
      } = this.layout;
      return bodyShowWidth / bodyReallyWidth * (bodyShowWidth - 4);
    },
    verticalLong() {
      const {
        bodyShowHeight,
        bodyReallyHeight
      } = this.layout;
      return bodyShowHeight / bodyReallyHeight * (bodyShowHeight - 4);
    },
    ...mapStates({
      selection: 'selection',
      columns: 'columns',
      tableData: 'data',
      fixedColumns: 'fixedColumns',
      rightFixedColumns: 'rightFixedColumns'
    })
  },
  watch: {
    height: {
      immediate: true,
      handler(value) {
        this.layout.setHeight(value);
      }
    },
    maxHeight: {
      immediate: true,
      handler(value) {
        this.layout.setMaxHeight(value);
      }
    },
    currentRowKey: {
      immediate: true,
      handler(value) {
        if (!this.rowKey) return;
        this.store.setCurrentRowKey(value);
      }
    },
    data: {
      immediate: true,
      handler(value) {
        this.store.commit('setData', value);
        this.$nextTick(() => this.doLayout());
      }
    },
    expandRowKeys: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.store.setExpandRowKeysAdapter(newVal);
        }
      }
    }
  },
  created() {
    this.tableId = 'el-table_' + tableIdSeed++;
    this.debouncedUpdateLayout = debounce(50, () => this.doLayout());
  },
  mounted() {
    this.bindEvents();
    this.store.updateColumns();
    this.doLayout();
    this.resizeState = {
      width: this.$el.offsetWidth,
      height: this.$el.offsetHeight
    };

    // init filters
    this.store.states.columns.forEach(column => {
      if (column.filteredValue && column.filteredValue.length) {
        this.store.commit('filterChange', {
          column,
          values: column.filteredValue,
          silent: true
        });
      }
    });
    this.$ready = true;
  },
  destroyed() {
    this.unbindEvents();
  },
  data() {
    const {
      hasChildren = 'hasChildren',
      children = 'children'
    } = this.treeProps;
    this.store = createStore(this, {
      rowKey: this.rowKey,
      defaultExpandAll: this.defaultExpandAll,
      selectOnIndeterminate: this.selectOnIndeterminate,
      // TreeTable 的相关配置
      indent: this.indent,
      lazy: this.lazy,
      lazyColumnIdentifier: hasChildren,
      childrenColumnName: children
    });
    const layout = new TableLayout({
      store: this.store,
      table: this,
      fit: this.fit,
      showHeader: this.showHeader
    });
    return {
      direction: '',
      cursorDown: false,
      originalOnSelectStart: null,
      thumbState: {},
      layout,
      isHidden: false,
      renderExpanded: null,
      resizeProxyVisible: false,
      resizeState: {
        width: null,
        height: null
      },
      // 是否拥有多级表头
      isGroup: false,
      scrollPosition: 'left'
    };
  }
};