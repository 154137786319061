export default {
  name: 'AppMain',
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.path;
    },
    isFull() {
      return this.$store.state.app.isTaskListFullScreen ? 'w-main-content-full' : '';
    }
  }
};