var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ElTableColumn", {
    attrs: {
      prop: "tableIndex",
      type: "",
      width: _vm.width,
      align: "center",
      fixed: "left",
      "class-name": "table-index-sort"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.selectable ? _c("el-checkbox", {
          attrs: {
            "true-label": 2,
            "false-label": 0,
            indeterminate: _vm.checkAll === 1
          },
          model: {
            value: _vm.checkAll,
            callback: function ($$v) {
              _vm.checkAll = $$v;
            },
            expression: "checkAll"
          }
        }) : _c("span", [_vm._v("序号"), _vm.sortable ? _c("i", {
          staticClass: "el-icon-sort"
        }) : _vm._e()]), _c("el-tooltip", {
          ref: "tooltip",
          attrs: {
            placement: "top",
            content: _vm.activateContent
          }
        })];
      },
      proxy: true
    }, {
      key: "default",
      fn: function ({
        row
      }) {
        return [_c("div", {
          class: _vm.selectable ? _vm.isChecked(row) ? "column-selected" : "column-hover" : "",
          staticStyle: {
            overflow: "hidden",
            "text-overflow": "ellipsis",
            "word-break": "keep-all"
          },
          style: {
            cursor: _vm.sortable ? "move" : "default"
          },
          on: {
            mouseenter: function ($event) {
              return _vm.onMouseenter($event, row);
            },
            mouseleave: _vm.onMouseleave
          }
        }, [_vm.selectable ? _c("el-checkbox", {
          key: row[_vm.primaryKey],
          attrs: {
            label: row[_vm.primaryKey],
            disabled: _vm.isDisabled(row)
          },
          model: {
            value: _vm.selectionId,
            callback: function ($$v) {
              _vm.selectionId = $$v;
            },
            expression: "selectionId"
          }
        }) : _vm._e(), _c("span", {
          staticClass: "index"
        }, [_vm._v(" " + _vm._s(row.tableIndex) + " ")])], 1)];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };