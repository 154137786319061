export default {
  name: 'wDrawer',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    size: {
      type: [String, Number],
      default: '500px'
    },
    appendToBody: {
      type: Boolean,
      default: true
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    wSize() {
      return this.$store.getters['size'];
    },
    visible: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);
      }
    }
  },
  methods: {
    toggleVisible(status) {
      this.visible = status === undefined ? !this.visible : status;
    }
  }
};