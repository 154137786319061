var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.tabsList.length > 1 || _vm.tabsList.length === 1 && _vm.tabsList[0].label !== "全部" ? _c("el-tabs", {
    class: [_vm.size === "mini" ? "w-tabs-mini" : "", _vm.tabsList.length > 1 ? "" : "prepareContainer"],
    attrs: {
      value: _vm.currentTab
    },
    on: {
      "tab-click": _vm.onTabClick
    }
  }, _vm._l(_vm.tabsList, function (item, index) {
    return _c("el-tab-pane", _vm._b({
      key: index
    }, "el-tab-pane", item, false));
  }), 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };