export default {
  name: 'radioGroup',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isCancel: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    size() {
      return this.$store.getters['w/size'];
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);
      }
    }
  },
  methods: {
    onClick(v) {
      if (this.disabled) return;
      const result = this.modelValue === v && !this.isCancel ? '' : v;
      this.value = result;
      this.$emit('change', result);
    }
  }
};