var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "multi-operate"
  }, [_vm._l(_vm.operates_, function (item, key, index) {
    return [item.show ? _c("ElButton", {
      key: key,
      attrs: {
        disabled: item.disabled,
        icon: `el-icon-${item.tipIcon}`,
        type: item.type,
        size: _vm.size
      },
      on: {
        click: function ($event) {
          return _vm.onClick(key);
        }
      }
    }, [_vm._v(" " + _vm._s(item.tip) + " ")]) : _vm._e()];
  }), _vm._t("default", null, {
    selection: _vm.selection
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };