import { copyText } from '@/utils/w';
import { Message } from 'element-ui';
export default {
  inserted(el, {
    value,
    modifiers
  }) {
    if (value) {
      el.addEventListener('click', () => {
        copyText(value);
        Message.success('复制成功');
      });
    }
  }
};