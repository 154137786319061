import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.push.js";
import { post } from '@/libs/request';
export default {
  name: 'normalGroup',
  props: {
    primaryKey: {
      type: String
    },
    row: {
      type: Object,
      default: () => ({})
    },
    $index: {
      type: Number
    },
    operates: {
      type: Object,
      default: () => ({})
    },
    query: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    size() {
      return this.$store.getters['size'];
    },
    operates_() {
      const operates = {
        edit: {
          tip: '编辑',
          tipIcon: 'edit',
          type: 'primary'
        },
        del: {
          tip: '删除',
          tipIcon: 'delete',
          type: 'danger',
          confirm: true
        },
        look: {
          tip: '详情',
          tipIcon: 'view'
        }
      };
      return Object.keys(this.operates).reduce((pre, k) => {
        const {
          show,
          disabled
        } = this.operates[k];
        pre[k] = {
          ...operates[k],
          ...this.operates[k],
          show: typeof show === 'function' ? show(this.row, this.$index) : show,
          disabled: typeof disabled === 'function' ? disabled(this.row, this.$index) : disabled
        };
        return pre;
      }, {});
    }
  },
  data() {
    return {
      moreOperate: false
    };
  },
  mounted() {
    this.$scopedSlots.default && this.$refs.dropdown.children.length === 0 && (this.moreOperate = true);
  },
  methods: {
    // 点击操作按钮事件
    async onClick(key) {
      try {
        const url = this.operates_[key].url;
        const primaryKey = this.row[this.primaryKey];
        switch (key) {
          case 'edit':
          case 'look':
            url && this.$router.push(`${url}?${this.primaryKey}=${primaryKey}`);
            break;
          case 'del':
            // 根据配置决定是否需要删除提示
            this.operates_.del.confirm && (await this.$confirm('确认删除吗？', '删除提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }));
            if (url) {
              await post(url, {
                ...this.query,
                [this.primaryKey]: primaryKey
              });
              this.$emit('done');
            }
            break;
          case 'status':
            if (url) {
              await post(url, {
                ...this.query,
                [this.primaryKey]: primaryKey,
                status: 1 - this.row.status
              });
              this.$emit('done');
            }
            break;
        }
        this.$emit(key);
      } catch (e) {}
    }
  }
};