var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-tree", _vm._b({
    ref: "tree",
    staticClass: "tree",
    attrs: {
      data: _vm.searchList,
      treeType: _vm.treeType
    },
    on: {
      change: _vm.onChange
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, "w-tree", _vm.column.searchOption, false));
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };