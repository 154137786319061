var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-dialog", {
    directives: [{
      name: "dialogDrag",
      rawName: "v-dialogDrag"
    }],
    ref: "dialog",
    attrs: {
      title: "表头排版",
      width: "1100px",
      "before-close": _vm.onClose
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c("el-button", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.edit === 0,
            expression: "edit === 0"
          }],
          attrs: {
            size: _vm.size,
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.onEdit(true);
            }
          }
        }, [_vm._v(" 编辑 ")]), _vm.edit ? _c("el-button", {
          attrs: {
            size: _vm.size,
            type: "primary"
          },
          on: {
            click: _vm.onSave
          }
        }, [_vm._v(" 保存 ")]) : _vm._e(), _c("el-tooltip", {
          attrs: {
            content: "重置除“表头标题”和“注释”以外的数据为初始值，且重置后列排序为初始排序方式",
            placement: "top"
          }
        }, [_c("el-button", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.edit,
            expression: "edit"
          }, {
            name: "p",
            rawName: "v-p",
            value: _vm.p,
            expression: "p"
          }],
          attrs: {
            size: _vm.size,
            type: "danger"
          },
          on: {
            click: _vm.onReset
          }
        }, [_vm._v(" 重置 ")])], 1), _vm.edit ? _c("el-button", {
          attrs: {
            size: _vm.size
          },
          on: {
            click: _vm.onQuit
          }
        }, [_vm._v(" 退出编辑 ")]) : _vm._e(), _c("el-button", {
          attrs: {
            size: _vm.size
          },
          on: {
            click: _vm.onClose
          }
        }, [_vm._v(" 关闭 ")]), _c("el-button", {
          attrs: {
            size: _vm.size
          },
          on: {
            click: _vm.onClear
          }
        }, [_vm._v(" 清除缓存 ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("el-form", {
    ref: "form",
    staticClass: "dialog-form",
    attrs: {
      model: _vm.model,
      size: _vm.size,
      disabled: !_vm.edit
    }
  }, [_c("el-table", {
    ref: "table",
    attrs: {
      data: _vm.model.rule,
      "row-key": "v",
      stripe: true,
      border: true,
      size: _vm.size
    }
  }, [_c("el-table-column", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.edit,
      expression: "edit"
    }],
    attrs: {
      align: "center",
      "min-width": "60"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm._v(" 排序 "), _c("el-tooltip", {
          attrs: {
            content: "用户自行拖动对应列的图标进行排序",
            placement: "top"
          }
        }, [_c("el-link", {
          attrs: {
            type: "primary",
            underline: false
          }
        }, [_c("i", {
          staticClass: "el-icon-info"
        })])], 1)];
      },
      proxy: true
    }, {
      key: "default",
      fn: function ({
        row,
        $index
      }) {
        return [!row.fixed ? _c("i", {
          class: [_vm.getIcon($index), "drag-btn", _vm.edit ? "cursor" : ""]
        }) : _vm._e()];
      }
    }])
  }), _c("TableColumn", {
    attrs: {
      prop: "label",
      label: "表头标题",
      content: "表头名称",
      minWidth: "100",
      rules: _vm.rules.label
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        row,
        $index
      }) {
        return [_c("w-input", {
          model: {
            value: row.label,
            callback: function ($$v) {
              _vm.$set(row, "label", $$v);
            },
            expression: "row.label"
          }
        })];
      }
    }])
  }), _c("TableColumn", {
    attrs: {
      prop: "headerTooltip",
      label: "注释",
      content: "表头标题的简要说明，填写则显示，不填写则不显示",
      minWidth: "240"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        row,
        $index
      }) {
        return [_c("el-input", {
          ref: `headerTooltipRef-${$index}`,
          attrs: {
            type: "textarea",
            size: _vm.size,
            autosize: {
              minRows: 1
            }
          },
          model: {
            value: row.headerTooltip,
            callback: function ($$v) {
              _vm.$set(row, "headerTooltip", $$v);
            },
            expression: "row.headerTooltip"
          }
        })];
      }
    }])
  }), _c("TableColumn", {
    attrs: {
      prop: "minWidth",
      label: "列最小宽度",
      content: "默认为自适应调节列宽，用户可按需自行调节列宽值，若不填写，则使用默认值",
      minWidth: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        row
      }) {
        return [_c("w-input", {
          attrs: {
            type: "number",
            min: "0"
          },
          model: {
            value: row.minWidth,
            callback: function ($$v) {
              _vm.$set(row, "minWidth", _vm._n($$v));
            },
            expression: "row.minWidth"
          }
        })];
      }
    }])
  }), _c("TableColumn", {
    attrs: {
      prop: "minWidth",
      label: "默认宽度",
      content: "当前表格默认列宽，供参考，不可编辑",
      minWidth: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        row
      }) {
        return [_c("span", {
          class: _vm.edit ? "" : "grey"
        }, [_vm._v(_vm._s(row.factWidth))])];
      }
    }])
  }), _c("TableColumn", {
    attrs: {
      prop: "color",
      label: "标题颜色",
      minWidth: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        row,
        $index
      }) {
        return [_c("el-color-picker", {
          attrs: {
            predefine: ["#19aa8d", "#f44336", "#e6a23c"]
          },
          model: {
            value: row.color,
            callback: function ($$v) {
              _vm.$set(row, "color", $$v);
            },
            expression: "row.color"
          }
        })];
      }
    }])
  }), _c("TableColumn", {
    attrs: {
      prop: "align",
      label: "内容对齐方式",
      content: "用户可自行选择对齐方式，不选择时则使用系统默认方式",
      minWidth: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        row,
        $index
      }) {
        return [_c("RadioGroup", {
          attrs: {
            isCancel: true,
            disabled: !_vm.edit,
            data: [{
              label: "左",
              value: "left",
              name: "align" + $index
            }, {
              label: "中",
              value: "center",
              name: "align" + $index
            }, {
              label: "右",
              value: "right",
              name: "align" + $index
            }]
          },
          model: {
            value: row.align,
            callback: function ($$v) {
              _vm.$set(row, "align", $$v);
            },
            expression: "row.align"
          }
        })];
      }
    }])
  }), _c("TableColumn", {
    attrs: {
      prop: "fixed",
      label: "冻结方式",
      content: "默认不固定，为保持良好的阅读体验，建议列固定总数不要超过五列",
      minWidth: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        row,
        $index
      }) {
        return [_c("RadioGroup", {
          attrs: {
            data: [{
              label: "左",
              value: "left",
              name: "fixed" + $index
            }, {
              label: "右",
              value: "right",
              name: "fixed" + $index
            }],
            disabled: !_vm.edit || row.label === "备注"
          },
          on: {
            change: function ($event) {
              _vm.model.rule = _vm.sortRule(_vm.model.rule);
            }
          },
          model: {
            value: row.fixed,
            callback: function ($$v) {
              _vm.$set(row, "fixed", $$v);
            },
            expression: "row.fixed"
          }
        })];
      }
    }])
  }), _c("TableColumn", {
    attrs: {
      prop: "disabled",
      label: "是否显示",
      content: "开关开启时则显示对应列，开关关闭时则不显示对应列",
      minWidth: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        row
      }) {
        return [_c("el-switch", {
          attrs: {
            "active-value": false,
            "inactive-value": true
          },
          model: {
            value: row.disabled,
            callback: function ($$v) {
              _vm.$set(row, "disabled", $$v);
            },
            expression: "row.disabled"
          }
        })];
      }
    }])
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };