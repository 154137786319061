import { makeOtherOperatesStatus } from '../../util';
import NormalGroup from './normalGroup.vue';
import RecycleGroup from './recycleGroup.vue';
export default {
  components: {
    NormalGroup,
    RecycleGroup
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    otherOperates: {
      type: Array,
      default: () => []
    },
    recycleOperates: {
      type: Array,
      default: () => ({})
    },
    normalOperate: {
      type: Object,
      default: () => ({})
    },
    moreOperate: {
      type: Boolean,
      default: false
    },
    isRecycle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    size() {
      return this.$store.getters['size'];
    }
  },
  methods: {
    makeOtherOperatesStatus,
    getOperateWidth() {
      const width = Math.ceil(this.$refs.operatesBoxRef.getBoundingClientRect().width * (this.size === 'mini' ? 1 : 1.45));
      if (width > 34) return width + 10;
      if (width > 0) return 45;
      return 0;
    }
  }
};