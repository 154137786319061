var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("codemirror", _vm._g(_vm._b({
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }, "codemirror", {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), _vm.$listeners));
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };