var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("span", [_vm._v("  ")]), _vm._l(_vm.iconList, function (item, index) {
    return _c("el-button", {
      key: index,
      staticClass: "w5 m0 p0",
      staticStyle: {
        "font-size": "16px"
      },
      attrs: {
        size: _vm.size,
        type: _vm.getType(item.value)
      },
      on: {
        click: function ($event) {
          return _vm.setIcon(item.value);
        }
      }
    }, [_c("i", {
      class: item.value
    })]);
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };