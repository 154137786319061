import { codemirror } from 'vue-codemirror-lite';
require('codemirror/mode/javascript/javascript');
export default {
  name: 'wCodeJs',
  components: {
    codemirror
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    inputValue: {
      get() {
        return this.modelValue || '';
      },
      set(v) {
        this.$emit('update:modelValue', v);
      }
    }
  },
  methods: {}
};