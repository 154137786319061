import _mergeJSXProps from "@vue/babel-helper-vue-jsx-merge-props";
import DfTimePicker from './src/picker/time-picker';
export default {
  components: {
    DfTimePicker
  },
  props: {
    modelValue: {
      type: [String, Date, Array]
    },
    editable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择时间'
    },
    startPlaceholder: {
      type: String,
      default: '开始时间'
    },
    endPlaceholder: {
      type: String,
      default: '结束时间'
    },
    isRange: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: 'HH:mm'
    },
    valueFormat: {
      type: String,
      default: 'HH:mm'
    },
    pickerOptions: {
      type: Object,
      default: () => ({})
    },
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    size() {
      return this.$store.getters['w/size'];
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v || undefined);
      }
    }
  },
  render() {
    const h = arguments[0];
    return h("df-time-picker", _mergeJSXProps([{
      "ref": 'timePickerRef'
    }, {
      "attrs": this.$attrs
    }, {}, {
      "props": this.$props
    }, {}, {
      "on": this.$listeners
    }, {
      "attrs": {
        "size": this.size
      },
      "class": 'w100',
      "scopedSlots": this.$scopedSlots,
      "model": {
        value: this.value,
        callback: $$v => {
          this.value = $$v;
        }
      }
    }]));
  }
};