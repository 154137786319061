export default {
  name: 'searchInput',
  props: {
    column: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      value: '',
      searchType: 'match',
      searchTypeList: [{
        label: '模糊',
        value: 'like'
      }, {
        label: '精确',
        value: 'match'
      }]
    };
  },
  methods: {
    onFocus() {
      this.$refs.input.$refs.input.focus();
    },
    onEmit(value, type) {
      this.$emit('search', undefined, {
        ...this.column,
        searchType: type === 'like' ? 'match' : 'like'
      }, false);
      this.$emit('search', value || undefined, {
        ...this.column,
        searchType: type
      }, true);
    },
    onSearch() {
      const column = {
        ...this.column
      };
      if (column.searchType === 'find') {
        this.onEmit(this.value, this.searchType);
        return;
      }
      this.$emit('search', this.value || undefined, column);
    }
  }
};