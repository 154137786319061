import "core-js/modules/es.error.cause.js";
import store from '@/store';
export default {
  inserted(el, binding, vnode) {
    const {
      value
    } = binding;
    const roles = store.getters && store.getters.roles;
    if (value && value instanceof Array && value.length > 0) {
      const hasRole = roles.some(role => {
        return value.includes(role);
      });
      if (!hasRole) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error(`请设置角色权限标签值`);
    }
  }
};