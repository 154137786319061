var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.ranged ? _c("el-input", _vm._b({
    directives: [{
      name: "clickoutside",
      rawName: "v-clickoutside",
      value: _vm.handleClose,
      expression: "handleClose"
    }],
    ref: "reference",
    staticClass: "el-date-editor",
    class: "el-date-editor--" + _vm.type,
    attrs: {
      readonly: !_vm.editable || _vm.readonly || _vm.type === "dates" || _vm.type === "week" || _vm.type === "years" || _vm.type === "months",
      disabled: _vm.pickerDisabled,
      size: _vm.pickerSize,
      name: _vm.name,
      placeholder: _vm.placeholder,
      value: _vm.displayValue,
      validateEvent: false
    },
    on: {
      focus: _vm.handleFocus,
      input: value => _vm.userInput = value,
      change: _vm.handleChange
    },
    nativeOn: {
      keydown: function ($event) {
        return _vm.handleKeydown.apply(null, arguments);
      },
      mouseenter: function ($event) {
        return _vm.handleMouseEnter.apply(null, arguments);
      },
      mouseleave: function ($event) {
        _vm.showClose = false;
      }
    }
  }, "el-input", _vm.firstInputId, false), [_c("i", {
    staticClass: "el-input__icon",
    class: _vm.triggerClass,
    attrs: {
      slot: "prefix"
    },
    on: {
      click: _vm.handleFocus
    },
    slot: "prefix"
  }), _vm.haveTrigger ? _c("i", {
    staticClass: "el-input__icon",
    class: [_vm.showClose ? "" + _vm.clearIcon : ""],
    attrs: {
      slot: "suffix"
    },
    on: {
      click: _vm.handleClickIcon
    },
    slot: "suffix"
  }) : _vm._e()]) : _c("div", {
    directives: [{
      name: "clickoutside",
      rawName: "v-clickoutside",
      value: _vm.handleClose,
      expression: "handleClose"
    }],
    ref: "reference",
    staticClass: "el-date-editor el-range-editor el-input__inner",
    class: ["el-date-editor--" + _vm.type, _vm.pickerSize ? `el-range-editor--${_vm.pickerSize}` : "", _vm.pickerDisabled ? "is-disabled" : "", _vm.pickerVisible ? "is-active" : ""],
    on: {
      click: _vm.handleRangeClick,
      mouseenter: _vm.handleMouseEnter,
      mouseleave: function ($event) {
        _vm.showClose = false;
      },
      keydown: _vm.handleKeydown
    }
  }, [_c("i", {
    class: ["el-input__icon", "el-range__icon", _vm.triggerClass]
  }), _c("input", _vm._b({
    staticClass: "el-range-input",
    attrs: {
      autocomplete: "off",
      placeholder: _vm.startPlaceholder,
      disabled: _vm.pickerDisabled,
      readonly: !_vm.editable || _vm.readonly,
      name: _vm.name && _vm.name[0]
    },
    domProps: {
      value: _vm.displayValue && _vm.displayValue[0]
    },
    on: {
      input: _vm.handleStartInput,
      change: _vm.handleStartChange,
      focus: _vm.handleFocus
    }
  }, "input", _vm.firstInputId, false)), _vm._t("range-separator", function () {
    return [_c("span", {
      staticClass: "el-range-separator"
    }, [_vm._v(_vm._s(_vm.rangeSeparator))])];
  }), _c("input", _vm._b({
    staticClass: "el-range-input",
    attrs: {
      autocomplete: "off",
      placeholder: _vm.endPlaceholder,
      disabled: _vm.pickerDisabled,
      readonly: !_vm.editable || _vm.readonly,
      name: _vm.name && _vm.name[1]
    },
    domProps: {
      value: _vm.displayValue && _vm.displayValue[1]
    },
    on: {
      input: _vm.handleEndInput,
      change: _vm.handleEndChange,
      focus: _vm.handleFocus
    }
  }, "input", _vm.secondInputId, false)), _vm.haveTrigger ? _c("i", {
    staticClass: "el-input__icon el-range__close-icon",
    class: [_vm.showClose ? "" + _vm.clearIcon : ""],
    on: {
      click: _vm.handleClickIcon
    }
  }) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };