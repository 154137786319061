var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-form", {
    ref: "wForm",
    staticClass: "mt20",
    attrs: {
      form: _vm.form
    },
    on: {
      done: v => _vm.$emit("done", v)
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };