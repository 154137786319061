var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-table-column", {
    attrs: {
      prop: _vm.prop,
      align: "center",
      "min-width": _vm.minWidth
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.content ? _c("el-tooltip", {
          attrs: {
            placement: "top"
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.content)
                }
              })];
            },
            proxy: true
          }], null, false, 1210623833)
        }, [_c("el-link", {
          attrs: {
            type: "primary",
            underline: false
          }
        }, [_c("i", {
          staticClass: "el-icon-info"
        })])], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function ({
        row,
        $index
      }) {
        return [_c("el-form-item", {
          attrs: {
            prop: `rule.${$index}.${_vm.prop}`,
            rules: _vm.rules
          },
          scopedSlots: _vm._u([_vm.rules ? {
            key: "error",
            fn: function ({
              error
            }) {
              return [_c("el-tooltip", {
                staticClass: "error-tip",
                attrs: {
                  content: error,
                  placement: "top"
                }
              }, [error ? _c("span", [_c("i", {
                staticClass: "el-icon-warning"
              })]) : _vm._e()])];
            }
          } : null], null, true)
        }, [_vm._t("default", null, {
          row: row,
          $index: $index
        })], 2)];
      }
    }], null, true)
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };