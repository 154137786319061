var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-dialog", {
    directives: [{
      name: "dialogDrag",
      rawName: "v-dialogDrag"
    }],
    attrs: {
      title: _vm.title,
      width: _vm.width,
      fullscreen: _vm.fullscreen
    },
    on: {
      cancel: function ($event) {
        _vm.visible = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_vm._t("footer", function () {
          return [!_vm.readonly ? _c("el-button", {
            directives: [{
              name: "p",
              rawName: "v-p",
              value: _vm.p.submit,
              expression: "p.submit"
            }],
            attrs: {
              loading: _vm.loading,
              size: _vm.size,
              type: "primary"
            },
            on: {
              click: _vm.onSubmit
            }
          }, [_vm._v(" " + _vm._s(_vm.submitText) + " ")]) : _vm._e(), _c("el-button", {
            attrs: {
              size: _vm.size
            },
            on: {
              click: function ($event) {
                _vm.visible = false;
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.cancleText) + " ")])];
        })];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("w-form", _vm._g(_vm._b({
    ref: "form",
    staticClass: "p20",
    attrs: {
      size: "small"
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_slot, slotName) {
      return {
        key: slotName,
        fn: function (props) {
          return [_vm._t(slotName, null, null, props)];
        }
      };
    })], null, true)
  }, "w-form", {
    ..._vm.$attrs,
    ..._vm.$props,
    title: undefined
  }, false), _vm.$listeners))], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };