import dayjs from 'dayjs';
export default {
  name: 'wDateTimeSelect',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  props: {
    modelValue: {
      type: String,
      default: dayjs().format('YYYY-MM-DD HH:mm')
    },
    size: {
      type: String
    },
    dateAttrs: {
      type: Object,
      default: () => ({})
    },
    timeAttrs: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    wSize() {
      return this.$store.getters['w/size'];
    },
    modelValue_() {
      return this.modelValue.split(' ');
    },
    dateValue: {
      get() {
        return this.modelValue_[0];
      },
      set(v) {
        v = [v, this.modelValue_[1]].join(' ');
        this.$emit('update:modelValue', v);
        this.$emit('change', v);
      }
    },
    timeValue: {
      get() {
        return this.modelValue_[1];
      },
      set(v) {
        v = [this.modelValue_[0], v].join(' ');
        this.$emit('update:modelValue', v);
        this.$emit('change', v);
      }
    }
  }
};