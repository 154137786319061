import pdf from 'vue-pdf';
import { ellipsisStr, getFileType } from '@/utils/w';
export default {
  inheritAttrs: false,
  components: {
    pdf
  },
  props: {
    title: {
      type: String,
      default: '预览'
    },
    buttonText: {
      type: String,
      default: '预览'
    },
    url: {
      type: String,
      default: '',
      required: true
    },
    // 文件名 按需传入，有时候需要预览本地文件，必须传入文件名，否则无法判断文件格式，会直接进入下载
    fileName: {
      type: String,
      default: ''
    },
    errMsg: {
      type: String,
      default: '缺少文件路径'
    },
    hasDown: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      pdfSrc: '',
      pdfTotalPages: 1,
      isReachBottom: false
    };
  },
  computed: {
    size() {
      return this.$store.getters['size'];
    },
    fileType() {
      return getFileType(this.fileName || this.url);
    }
  },
  watch: {
    url: {
      immediate: true,
      handler(val) {
        this.pdfSrc = val;
      }
    },
    visible: {
      immediate: true,
      handler(v) {
        v && this.getPdfTotalPages();
      }
    }
  },
  methods: {
    ellipsisStr(str = '', length = 4) {
      return ellipsisStr(str, length);
    },
    getPdfTotalPages() {
      let loadingTask = pdf.createLoadingTask(this.pdfSrc);
      loadingTask.promise.then(pdf_res => {
        this.pdfSrc = loadingTask;
        this.pdfTotalPages = pdf_res.numPages;
      }).catch(err => {
        // err
        // console.log(err)
      });
    },
    handleScroll(content) {
      let scrollH = content.scrollHeight;
      let height = content.clientHeight;
      let scrollTop = content.scrollTop;
      if (scrollTop + height + 10 >= scrollH) {
        this.$emit('onReachBottom', this.isReachBottom);
        this.isReachBottom = true;
      }
    },
    open() {
      if (!this.url) return this.$message.warning(this.errMsg);
      if (this.fileType === 'other') {
        const link = document.createElement('a');
        link.setAttribute('download', this.fileName || this.url);
        link.setAttribute('target', '_blank');
        link.href = this.url;
        return link.click();
      }
      if (this.fileType === 'img') {
        this.$emit('num-pages', 1);
      }
      this.visible = true;
    },
    handlePrintOrDownload() {
      window.open(this.url);
    }
  }
};