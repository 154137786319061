import dayjs from 'dayjs';
import { getRangeHours, getRangeMinutes, modifyTime } from 'element-ui/src/utils/date-util';
import RepeatClick from 'element-ui/src/directives/repeat-click';
import { makeTimeList, toValidTime } from '../../util';
export default {
  directives: {
    repeatClick: RepeatClick
  },
  props: {
    date: {},
    defaultValue: {},
    // reserved for future use
    showSeconds: {
      type: Boolean,
      default: true
    },
    arrowControl: Boolean,
    amPmMode: {
      type: String,
      default: '' // 'a': am/pm; 'A': AM/PM
    },

    start: {
      type: Object
    },
    end: {
      type: Object
    },
    step: {
      type: Object
    }
  },
  computed: {
    date_() {
      return toValidTime(this.date, this.start, this.end, this.step, this.showSeconds);
    },
    hours() {
      return this.date_.hour();
    },
    minutes() {
      return this.date_.minute();
    },
    seconds() {
      return this.date_.second();
    },
    hoursList() {
      // this.$nextTick(() => this.adjustSpinner('hours', this.hours))
      return makeTimeList(this.start.hour(), this.end.hour(), this.step.h || this.step.hour, 'hour');
    },
    minutesList() {
      const hour = this.date_.hour();
      const start = this.start.hour();
      const end = this.end.hour();
      // this.$nextTick(() => this.adjustSpinner('minutes', this.minutes))
      return makeTimeList(hour === start ? this.start.minute() : 0, hour === end ? this.end.minute() : 60, this.step.m || this.step.minute, 'minute');
    },
    secondsList() {
      const h = this.date_.hour();
      const m = this.date_.minute();
      const sh = this.start.hour();
      const sm = this.start.minute();
      const eh = this.end.hour();
      const em = this.end.minute();
      return makeTimeList(h === sh && m === sm ? this.start.second() : 0, h === eh && m === em ? this.end.second() : 0, this.step.s || this.step.second, 'second');
    },
    arrowHourList() {
      const hours = this.hours;
      return [hours > 0 ? hours - 1 : undefined, hours, hours < 23 ? hours + 1 : undefined];
    },
    arrowMinuteList() {
      const minutes = this.minutes;
      return [minutes > 0 ? minutes - 1 : undefined, minutes, minutes < 59 ? minutes + 1 : undefined];
    },
    arrowSecondList() {
      const seconds = this.seconds;
      return [seconds > 0 ? seconds - 1 : undefined, seconds, seconds < 59 ? seconds + 1 : undefined];
    }
  },
  data() {
    return {
      selectableRange: [],
      currentScrollbar: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      !this.arrowControl && this.bindScrollEvent();
    });
  },
  methods: {
    increase() {
      this.scrollDown(1);
    },
    decrease() {
      this.scrollDown(-1);
    },
    modifyDateField(type, value) {
      switch (type) {
        case 'hours':
          this.$emit('change', modifyTime(this.date, value, this.minutes, this.seconds), type);
          break;
        case 'minutes':
          this.$emit('change', modifyTime(this.date, this.hours, value, this.seconds), type);
          break;
        case 'seconds':
          this.$emit('change', modifyTime(this.date, this.hours, this.minutes, value), type);
          break;
      }
    },
    handleClick(type, {
      value
    }) {
      this.modifyDateField(type, value);
      this.emitSelectRange(type);
      this.adjustSpinner(type, value);
    },
    emitSelectRange(type) {
      if (type === 'hours') {
        this.$emit('select-range', 0, 2);
      } else if (type === 'minutes') {
        this.$emit('select-range', 3, 5);
      } else if (type === 'seconds') {
        this.$emit('select-range', 6, 8);
      }
      this.currentScrollbar = type;
    },
    bindScrollEvent() {
      const bindFunction = type => {
        this.$refs[type].wrap.onscroll = e => {
          // TODO: scroll is emitted when set scrollTop programatically
          // should find better solutions in the future!
          this.handleScroll(type, e);
        };
      };
      bindFunction('hours');
      bindFunction('minutes');
      bindFunction('seconds');
    },
    handleScroll(type) {
      const key = Math.min(Math.round((this.$refs[type].wrap.scrollTop - (this.scrollBarHeight(type) * 0.5 - 10) / this.typeItemHeight(type) + 3) / this.typeItemHeight(type)), type === 'hours' ? 23 : 59);
      const list = this[`${type}List`];
      const value = list[key] === undefined ? list[list.length - 1] : list[key];
      this.modifyDateField(type, value);
    },
    // NOTE: used by datetime / date-range panel
    //       renamed from adjustScrollTop
    //       should try to refactory it
    adjustSpinners() {
      this.adjustSpinner('hours', this.hours);
      this.adjustSpinner('minutes', this.minutes);
      this.adjustSpinner('seconds', this.seconds);
    },
    adjustCurrentSpinner(type) {
      this.adjustSpinner(type, this[type]);
    },
    adjustSpinner(type, value) {
      if (this.arrowControl) return;
      const el = this.$refs[type].wrap;
      if (el) {
        el.scrollTop = Math.max(0, this[`${type}List`].findIndex(v => v === value) * this.typeItemHeight(type));
      }
    },
    scrollDown(step) {
      if (!this.currentScrollbar) {
        this.emitSelectRange('hours');
      }
      const label = this.currentScrollbar;
      const hoursList = this.hoursList;
      let now = this[label];
      if (this.currentScrollbar === 'hours') {
        let total = Math.abs(step);
        step = step > 0 ? 1 : -1;
        let length = hoursList.length;
        while (length-- && total) {
          now = (now + step + hoursList.length) % hoursList.length;
          if (hoursList[now]) {
            continue;
          }
          total--;
        }
        if (hoursList[now]) return;
      } else {
        now = (now + step + 60) % 60;
      }
      this.modifyDateField(label, now);
      this.adjustSpinner(label, now);
      this.$nextTick(() => this.emitSelectRange(this.currentScrollbar));
    },
    amPm(hour) {
      let shouldShowAmPm = this.amPmMode.toLowerCase() === 'a';
      if (!shouldShowAmPm) return '';
      let isCapital = this.amPmMode === 'A';
      let content = hour < 12 ? ' am' : ' pm';
      if (isCapital) content = content.toUpperCase();
      return content;
    },
    typeItemHeight(type) {
      return this.$refs[type].$el.querySelector('li').offsetHeight;
    },
    scrollBarHeight(type) {
      return this.$refs[type].$el.offsetHeight;
    }
  }
};