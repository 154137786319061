var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(_vm.type === "radios" ? "Radios" : "Tabs", _vm._b({
    tag: "component",
    model: {
      value: _vm.currentTab,
      callback: function ($$v) {
        _vm.currentTab = $$v;
      },
      expression: "currentTab"
    }
  }, "component", {
    ..._vm.$props,
    tabsList: _vm.tabsList_
  }, false));
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };