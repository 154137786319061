export default {
  name: 'searchTimerange',
  props: {
    column: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    size() {
      return this.$store.getters['size'];
    }
  },
  data() {
    return {
      value: undefined
    };
  },
  methods: {
    onFocus() {
      this.$refs.timePickerRef.$refs.timePickerRef.focus();
    },
    onChangeFocus({
      $el
    }, focus) {
      if (!focus) return $el.style.marginBottom = 0;
      $el.style.marginBottom = '230px';
    },
    onChange() {
      if (!this.value) return this.$emit('search', undefined, this.column);
      let [start, end] = this.value;
      this.$emit('search', [start, end].map(i => i.replace(/:/g, '')), this.column);
    }
  }
};