import { codemirror } from 'vue-codemirror-lite';
require('codemirror/mode/htmlmixed/htmlmixed');
export default {
  name: 'wCode',
  components: {
    codemirror
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);
      }
    }
  },
  methods: {}
};