var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", _vm._g(_vm._b({
    attrs: {
      visible: _vm.visible,
      width: _vm.width_,
      top: "0"
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._t("title", function () {
          return [_c("div", {
            staticClass: "f16"
          }, [_vm._v(_vm._s(_vm.title))])];
        })];
      },
      proxy: true
    }], null, true)
  }, "el-dialog", {
    ..._vm.$props,
    ..._vm.$attrs
  }, false), _vm.$listeners), [_c("div", {
    ref: "dialogContent",
    staticClass: "y-dialog-content p20",
    on: {
      scroll: _vm.handleScroll
    }
  }, [_vm._t("default")], 2), _c("div", {
    staticClass: "footer"
  }, [_vm._t("footer", function () {
    return [_c("el-button", {
      attrs: {
        type: "primary",
        size: _vm.size
      },
      on: {
        click: _vm.confirm
      }
    }, [_vm._v("确认")]), _c("el-button", {
      attrs: {
        size: _vm.size
      },
      on: {
        click: _vm.cancel
      }
    }, [_vm._v("取消")])];
  })], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };