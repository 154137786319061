import { merge } from 'lodash';
export default {
  name: 'searchDaterange',
  props: {
    column: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    searchType() {
      switch (this.column.searchType) {
        case 'dateint':
        case 'datebetween':
          return 'daterange';
        default:
          return this.column.searchType;
      }
    },
    searchOption() {
      const defaultOption = {
        pickerOptions: {
          disabledDate: {
            end: 'today'
          }
        }
      };
      return this.column.searchOption ? merge(this.column.searchOption, defaultOption) : defaultOption;
    }
  },
  data() {
    return {
      value: []
    };
  },
  methods: {
    onFocus() {
      this.$refs.daterange.$refs.datePicker.focus();
    },
    onChangeFocus({
      $el
    }, focus) {
      if (!focus) return $el.style.marginBottom = 0;
      switch (this.searchType) {
        case 'daterange':
          return $el.style.marginBottom = '260px';
        case 'monthrange':
          return $el.style.marginBottom = '180px';
      }
    },
    onSearch() {
      this.$emit('search', this.value ? this.column.searchType === 'dateint' ? this.value.map(i => i.replace(/-/g, '').replace(/:/g, '')) : this.value : undefined, this.column);
    }
  }
};