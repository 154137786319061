export default {
  name: 'wInput',
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    exchange_rate: {
      type: Number,
      default: 1
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    wSize() {
      return this.$store.getters['size'];
    },
    inputValue: {
      get() {
        this.jpy = this.$w_fun.other2Jpy(this.modelValue, this.exchange_rate);
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);
      }
    }
  },
  data() {
    return {
      jpy: undefined,
      c: 0
    };
  },
  mounted() {
    this.jpy = this.$w_fun.other2Jpy(this.inputValue, this.exchange_rate);
  },
  methods: {
    changeJpy(v) {
      if (this.c === 0) {
        return;
      }
      this.inputValue = this.$w_fun.jpy2Other(v, this.exchange_rate);
    }
  }
};