export default {
  name: 'wDialogForm',
  inheritAttrs: false,
  props: {
    // 权限验证地址对象
    p: {
      type: Object,
      default: () => ({
        submit: false
      })
    },
    title: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    submitText: {
      type: String,
      default: '提交'
    },
    resetText: {
      type: String,
      default: '重置'
    },
    cancleText: {
      type: String,
      default: '取消'
    },
    width: {
      type: [String, Number],
      default: '500px'
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    size() {
      return this.$store.getters['size'];
    }
  },
  data() {
    return {
      visible: false,
      loading: false
    };
  },
  methods: {
    toggleVisible() {
      this.visible = !this.visible;
    },
    resetFields() {
      this.$refs.form.resetFields();
    },
    async onSubmit() {
      try {
        this.loading = true;
        // 这里因为解构 submit返回undefined可以终止执行
        const data = await this.$refs.form.submit();
        this.loading = false;
        if (data !== false) {
          this.toggleVisible();
          this.$emit('done', data.data);
        }
      } catch (e) {
        this.loading = false;
      }
    }
  }
};