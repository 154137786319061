var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-pagination", _vm._g(_vm._b({
    attrs: {
      "page-size": _vm.pageSize_,
      "current-page": _vm.currentPage_
    },
    on: {
      "update:pageSize": function ($event) {
        _vm.pageSize_ = $event;
      },
      "update:page-size": function ($event) {
        _vm.pageSize_ = $event;
      },
      "update:currentPage": function ($event) {
        _vm.currentPage_ = $event;
      },
      "update:current-page": function ($event) {
        _vm.currentPage_ = $event;
      },
      "size-change": _vm.onSizeChange,
      "current-change": _vm.onCurrentChange
    }
  }, "el-pagination", {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), _vm.$listeners));
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };