var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", _vm._g(_vm._b({
    ref: "form",
    staticClass: "dp-f flex-wrap justify-content-between",
    attrs: {
      model: _vm.model,
      rules: _vm.rules
    },
    nativeOn: {
      submit: function ($event) {
        $event.preventDefault();
      }
    }
  }, "el-form", {
    ..._vm.$attrs,
    ..._vm.$props,
    title: undefined
  }, false), _vm.$listeners), [_vm._l(_vm.formItems, function (item, index) {
    return [(typeof item.show === "function" ? item.show(_vm.model) : item.show) ? _c("el-form-item", {
      key: item.key,
      staticStyle: {
        "word-wrap": "break-word",
        "word-break": "break-all"
      },
      style: {
        width: item.width || _vm.itemWidth,
        marginBottom: _vm.readonly ? "0" : "18px"
      },
      attrs: {
        prop: item.prop
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function () {
          return [_vm._t(`${item.prop}Label`, function () {
            return [_vm._v(" " + _vm._s(item.label) + " "), item.label ? [item.tooltip ? _c("el-tooltip", {
              attrs: {
                content: item.tooltip
              }
            }, [_c("el-button", {
              attrs: {
                type: "text",
                size: _vm.size
              }
            }, [_c("i", {
              staticClass: "el-icon-info"
            })])], 1) : _vm._e(), _vm._v(" " + _vm._s(_vm.labelSuffix) + " ")] : _vm._e()];
          }, {
            options: _vm.options,
            model: _vm.model,
            item: item
          })];
        },
        proxy: true
      }], null, true)
    }, [_vm.formTypeList.includes(item.formType) ? [_vm.readonly || item.readonly ? [item.formType === "upload" ? _c("w-enclosure", {
      attrs: {
        enclosure: _vm.model[item.prop]
      }
    }) : item.formType === "editor" ? [_c("pre", {
      domProps: {
        innerHTML: _vm._s(_vm.model[item.prop])
      }
    })] : [_c("div", {
      directives: [{
        name: "copy",
        rawName: "v-copy",
        value: item.copy ? _vm.model[item.prop] || "-" : "",
        expression: "item.copy ? (model[item.prop] || '-') : ''"
      }],
      class: item.copy ? "cursor" : "",
      domProps: {
        innerHTML: _vm._s(_vm.model[item.prop] || "-")
      }
    })]] : [["text", "textarea"].includes(item.formType) ? _c("w-input", _vm._g(_vm._b({
      attrs: {
        type: item.formType
      },
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "w-input", item.attrs, false), item.methods)) : item.formType === "code" ? _c("w-code", _vm._g(_vm._b({
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "w-code", item.attrs, false), item.methods)) : item.formType === "codeJs" ? _c("w-code-js", _vm._g(_vm._b({
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "w-code-js", item.attrs, false), item.methods)) : item.formType === "autocomplete" ? _c("el-autocomplete", _vm._g(_vm._b({
      staticClass: "w100",
      attrs: {
        size: item.attrs.size || _vm.size,
        debounce: 500,
        "fetch-suggestions": (queryString, cb) => _vm.querySearch(queryString, cb, item.prop)
      },
      on: {
        select: function ($event) {
          return _vm.validateField(item.prop);
        }
      },
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "el-autocomplete", item.attrs, false), item.methods)) : item.formType === "cascader" ? _c("el-cascader", _vm._g(_vm._b({
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "el-cascader", item.attrs, false), item.methods)) : item.formType === "map" ? _c("w-map", _vm._g(_vm._b({
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "w-map", item.attrs, false), item.methods)) : item.formType === "number" ? _c("w-input", _vm._g(_vm._b({
      attrs: {
        type: "number"
      },
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "w-input", item.attrs, false), item.methods)) : item.formType === "amount" ? _c("w-input-amount", _vm._g(_vm._b({
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "w-input-amount", item.attrs, false), item.methods)) : item.formType === "radio" ? _c("el-radio-group", _vm._g(_vm._b({
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "el-radio-group", item.attrs, false), item.methods), _vm._l(_vm.options[item.prop], function (itm, idx) {
      return _c(item.attrs && item.attrs.isButton ? "el-radio-button" : "el-radio", _vm._b({
        key: idx,
        tag: "component",
        attrs: {
          label: itm.value
        }
      }, "component", itm, false), [_vm._v(" " + _vm._s(itm.label) + " ")]);
    }), 1) : item.formType === "checkbox" ? _c("el-checkbox-group", _vm._g(_vm._b({
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "el-checkbox-group", item.attrs, false), item.methods), _vm._l(_vm.options[item.prop], function (itm, idx) {
      return _c(item.attrs && item.attrs.isButton ? "el-checkbox-button" : "el-checkbox", _vm._b({
        key: idx,
        tag: "component",
        attrs: {
          label: itm.value
        }
      }, "component", itm, false), [_vm._v(" " + _vm._s(itm.label) + " ")]);
    }), 1) : item.formType === "select" ? _c("w-select", _vm._g(_vm._b({
      attrs: {
        data: _vm.options[item.prop]
      },
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "w-select", item.attrs, false), item.methods)) : item.formType === "remote" ? _c("w-select", _vm._g(_vm._b({
      attrs: {
        data: _vm.options[item.prop],
        remote: true,
        reserveKeyword: true,
        remoteMethod: k => _vm.remoteMethod(item, k)
      },
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "w-select", item.attrs, false), item.methods)) : item.formType === "status" || item.formType === "boolean" ? _c("el-radio-group", _vm._g(_vm._b({
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "el-radio-group", item.attrs, false), item.methods), [_c(item.attrs && item.attrs.isButton ? "el-radio-button" : "el-radio", {
      tag: "component",
      attrs: {
        label: 1
      }
    }, [_vm._v(_vm._s(item.formType === "status" ? "正常" : "是"))]), _c(item.attrs && item.attrs.isButton ? "el-radio-button" : "el-radio", {
      tag: "component",
      attrs: {
        label: 0
      }
    }, [_vm._v(_vm._s(item.formType === "status" ? "禁用" : "否"))])], 1) : ["tree", "treeRadio", "treeRadioFree", "treeSelect", "treeSelectFree"].includes(item.formType) ? _c(item.formType === "tree" ? "w-tree" : "w-input-tree", _vm._g(_vm._b({
      ref: `${item.prop}Tree`,
      refInFor: true,
      tag: "component",
      attrs: {
        data: _vm.options[item.prop],
        treeType: item.formType
      },
      on: {
        change: (v, nodes, isFirst) => !isFirst && _vm.validateField(item.prop)
      },
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "component", item.attrs, false), item.methods)) : item.formType === "switch" ? _c("el-switch", _vm._g(_vm._b({
      attrs: {
        "active-text": _vm.options[item.prop][1].label,
        "active-value": _vm.options[item.prop][1].value,
        "inactive-text": _vm.options[item.prop][0].label,
        "inactive-value": _vm.options[item.prop][0].value
      },
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "el-switch", item.attrs, false), item.methods)) : item.formType === "timeSelect" ? _c("el-time-select", _vm._g(_vm._b({
      staticClass: "w100",
      attrs: {
        "picker-options": item.attrs.pickerOptions || _vm.pickerOptions,
        "value-format": item.attrs.valueFormat || "HH:mm",
        format: item.attrs.format || "HH:mm",
        size: item.attrs.size || _vm.size
      },
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "el-time-select", item.attrs, false), item.methods)) : item.formType === "timePicker" ? _c("el-time-picker", _vm._g(_vm._b({
      staticClass: "w100",
      attrs: {
        "value-format": item.attrs.valueFormat || "HH:mm",
        format: item.attrs.format || "HH:mm",
        size: item.attrs.size || _vm.size
      },
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "el-time-picker", item.attrs, false), item.methods)) : item.formType === "datePicker" ? _c("w-date-picker", _vm._g(_vm._b({
      attrs: {
        size: item.attrs.size || _vm.size
      },
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "w-date-picker", item.attrs, false), item.methods)) : item.formType === "upload" ? _c("w-upload", _vm._g(_vm._b({
      ref: `${item.prop}Upload`,
      refInFor: true,
      on: {
        change: function ($event) {
          return _vm.validateField(item.prop);
        }
      },
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "w-upload", item.attrs, false), item.methods)) : item.formType === "inputArr" ? [_vm._l(_vm.model[item.prop], function (itm, idx) {
      return _c("div", {
        key: idx,
        staticClass: "mb5 input-arr-box"
      }, [_c("w-input", _vm._g(_vm._b({
        ref: "inputArr",
        refInFor: true,
        staticClass: "mr10",
        staticStyle: {
          width: "calc(100% - 30px)"
        },
        attrs: {
          type: "textarea",
          autosize: {
            minRows: 1
          },
          placeholder: item.attrs.placeholder || `${idx + 1}.请输入${item.label}`
        },
        model: {
          value: _vm.model[item.prop][idx],
          callback: function ($$v) {
            _vm.$set(_vm.model[item.prop], idx, $$v);
          },
          expression: "model[item.prop][idx]"
        }
      }, "w-input", item.attrs, false), item.methods)), _vm.model[item.prop].length != 1 ? _c("el-button", {
        staticClass: "oprBtn",
        staticStyle: {
          color: "#ffffff"
        },
        attrs: {
          size: item.attrs.size || _vm.size,
          type: "danger"
        },
        on: {
          click: function ($event) {
            return _vm.delValidate(item.prop, idx, item.methods.del);
          }
        }
      }, [_c("i", {
        staticClass: "el-icon-minus"
      })]) : _vm._e()], 1);
    }), _c("div", {
      staticClass: "point",
      attrs: {
        size: _vm.size,
        type: "text"
      },
      on: {
        click: function ($event) {
          return _vm.addValidate(item.prop, item.methods.add);
        }
      }
    }, [_vm._v(" 添加 ")])] : _vm._e(), item.formType === "editor" ? _c("w-editor", _vm._g(_vm._b({
      ref: `${item.prop}Editor`,
      refInFor: true,
      model: {
        value: _vm.model[item.prop],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.prop, $$v);
        },
        expression: "model[item.prop]"
      }
    }, "w-editor", item.attrs, false), item.methods)) : _vm._e()]] : _vm._t(item.formType, null, {
      options: _vm.options,
      model: _vm.model,
      item: item
    })], 2) : _vm._e()];
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };