var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-select", {
    ref: "select",
    attrs: {
      data: _vm.searchList,
      multiple: ""
    },
    on: {
      change: _vm.onChange
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };