var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "dp-f align-items-center border-E4E7ED-bottom w-tab-right-handle"
  }, [_c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "el-icon-full-screen ml5 mr5 cursor",
    class: [_vm.fontSize, _vm.full ? "green" : ""],
    attrs: {
      title: "全屏"
    },
    on: {
      click: function ($event) {
        return _vm.onClick("full");
      }
    }
  }), _c("i", {
    staticClass: "el-icon-refresh-right ml5 mr5 cursor",
    class: [_vm.fontSize],
    attrs: {
      title: "刷新"
    },
    on: {
      click: function ($event) {
        return _vm.onClick("refresh");
      }
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };