var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("table", {
    staticClass: "el-month-table",
    on: {
      click: _vm.handleMonthTableClick,
      mousemove: _vm.handleMouseMove
    }
  }, [_c("tbody", _vm._l(_vm.rows, function (row, key) {
    return _c("tr", {
      key: key
    }, _vm._l(row, function (cell, key) {
      return _c("td", {
        key: key,
        class: _vm.getCellStyle(cell)
      }, [_c("div", [_c("a", {
        staticClass: "cell"
      }, [_vm._v(_vm._s(_vm.months[cell.text]))])])]);
    }), 0);
  }), 0)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };