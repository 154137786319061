import { merge } from 'lodash';
export default {
  name: 'searchDate',
  props: {
    column: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    searchOption() {
      const defaultOption = {
        pickerOptions: {
          disabledDate: {
            end: 'today'
          }
        }
      };
      return this.column.searchOption ? merge(this.column.searchOption, defaultOption) : defaultOption;
    }
  },
  data() {
    return {
      value: ''
    };
  },
  methods: {
    onFocus() {
      this.$refs.date.$refs.datePicker.focus();
    },
    onChangeFocus({
      $el
    }, focus) {
      if (!focus) return $el.style.marginBottom = 0;
      switch (this.column.searchType) {
        case 'date':
          return $el.style.marginBottom = '260px';
        case 'month':
          return $el.style.marginBottom = '180px';
        case 'year':
          return $el.style.marginBottom = '180px';
      }
    },
    onSearch() {
      this.$emit('search', this.value ? this.value : undefined, this.column);
    }
  }
};