import { mapGetters } from 'vuex';
import Logo from './Logo';
import SidebarItem from './SidebarItem';
import variables from '@/assets/styles/variables.scss';
import { deepClone } from '@/utils/w';
export default {
  components: {
    SidebarItem,
    Logo
  },
  computed: {
    ...mapGetters(['permission_routes', 'sidebar']),
    currentMenus() {
      const permission_routes = deepClone(this.permission_routes);
      for (const item of permission_routes) {
        if (!item.hidden && item.path) {
          const item_path = item.path;
          const current_path = this.$route.path;
          if (current_path.substring(0, item_path.length) === item_path) {
            return item.children.map(child => {
              child.path = item_path + '/' + child.path;
              return child;
            });
          }
        }
      }
      return [];
    },
    activeMenu() {
      const route = this.$route;
      const {
        meta,
        path
      } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return true;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    }
  }
};