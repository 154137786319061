var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.src_ ? _c("el-image", _vm._g(_vm._b({
    attrs: {
      src: _vm.src_,
      "preview-src-list": _vm.previewSrcList_
    },
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_slot, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  }, "el-image", {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), _vm.$listeners)) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };