var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "recycle-group-button"
  }, _vm._l(_vm.operates, function (item, index) {
    return _c("ElButton", {
      key: index,
      attrs: {
        disabled: !item.show,
        type: item.type,
        size: _vm.size
      },
      on: {
        click: function ($event) {
          return _vm.onClick(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]);
  }), 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };