export default {
  name: 'tableColumn',
  props: {
    prop: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    minWidth: {
      type: [String, Number],
      default: 0
    },
    rules: {
      type: Array
    }
  }
};