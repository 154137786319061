var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-radio-group", {
    attrs: {
      size: _vm.size
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, [_vm.type === "button" ? _vm._l(_vm.data, function (item, index) {
    return _c("el-radio-button", {
      key: index,
      attrs: {
        label: item.value,
        name: item.name
      },
      nativeOn: {
        click: function ($event) {
          $event.preventDefault();
          return _vm.onClick(item.value);
        }
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }) : _vm._e(), _vm.type === "radio" ? _vm._l(_vm.data, function (item, index) {
    return _c("el-radio", {
      key: index,
      attrs: {
        label: item.value,
        name: item.name
      },
      nativeOn: {
        click: function ($event) {
          $event.preventDefault();
          return _vm.onClick(item.value);
        }
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };