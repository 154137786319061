var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-select", _vm._b({
    ref: "selectRef",
    staticStyle: {
      width: "250px"
    },
    attrs: {
      loading: _vm.loading,
      data: _vm.searchList
    },
    on: {
      change: _vm.onSearch,
      clear: _vm.onSearch
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, "w-select", _vm.searchOption, false));
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };