export default {
  name: 'pageOperate',
  props: {
    currentTab: {
      type: String,
      default: ''
    }
  },
  computed: {
    size: {
      get() {
        return this.$store.getters['size'];
      },
      set(val) {
        this.$store.dispatch('w/setSize', val);
      }
    },
    isMini() {
      return this.size === 'mini';
    },
    fontSize() {
      return this.isMini ? 'f16' : 'f18';
    },
    full: {
      get() {
        return this.$store.state.app.isTaskListFullScreen;
      },
      set(v) {
        this.$store.commit('toggleFullScreenState', v);
      }
    }
  },
  data() {
    return {
      api: '',
      visible: false,
      isEdit: false,
      defaultInfo: '',
      info: ''
    };
  },
  methods: {
    // 按钮点击事件
    onClick(key) {
      switch (key) {
        case 'full':
          this.full = !this.full;
          break;
        case 'size':
          this.size = this.isMini ? 'small' : 'mini';
          break;
      }
      this.$emit('refresh');
    }
  }
};