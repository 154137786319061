var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.tabsList.length > 1 || _vm.tabsList.length === 1 && _vm.tabsList[0].label !== "全部" ? _c("el-radio-group", {
    attrs: {
      value: _vm.currentTab,
      size: _vm.size
    },
    on: {
      input: _vm.onInput
    }
  }, _vm._l(_vm.tabsList, function (item, index) {
    return _c("el-radio-button", {
      key: index,
      attrs: {
        label: item.name
      }
    }, [_vm._v(" " + _vm._s(item.label) + " "), _c("span", {
      staticClass: "w-badge"
    }, [item.total ? _c("i", [_vm._v(_vm._s(item.total))]) : _vm._e()])]);
  }), 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };