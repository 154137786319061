var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.enclosure_.length ? _c("div", [_c("el-button", {
    attrs: {
      size: _vm.wSize,
      type: "text"
    },
    on: {
      click: _vm.onClick
    }
  }, [_c("i", {
    staticClass: "el-icon-download"
  })]), _c("w-drawer", _vm._b({
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, "w-drawer", {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), _vm._l(_vm.enclosure_, function (item, index) {
    return _c("el-link", {
      key: index,
      staticClass: "enclosure-item",
      attrs: {
        type: "primary",
        underline: false,
        href: item.url,
        target: "_blank"
      }
    }, [_vm._v(" " + _vm._s(index + 1) + ". " + _vm._s(item.name) + " ")]);
  }), 1)], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };