export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    width: {
      type: [String, Number],
      default: 500
    },
    modalAppendToBody: {
      type: Boolean,
      default: true
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    closeOnClickModal: {
      type: Boolean,
      default: false
    },
    closeOnPressEscape: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    size() {
      return this.$store.getters['size'];
    },
    visible: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);
      }
    },
    width_() {
      return typeof this.width === 'number' ? `${this.width}px` : this.width;
    }
  },
  methods: {
    cancel() {
      this.visible = false;
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('confirm');
    },
    handleScroll() {
      const dialogContent = this.$refs.dialogContent;
      this.$emit('onscroll', dialogContent);
      let scrollTop = dialogContent.scrollTop;
      let scrollHeight = dialogContent.scrollHeight;
      let height = dialogContent.getBoundingClientRect().height;
      if (Math.ceil(scrollTop + height) >= scrollHeight) {
        this.$emit('onReachBottom', dialogContent);
      }
    }
  }
};