import { debounce } from 'lodash';
import { ellipsisStr } from '@/utils/w';
export default {
  name: 'wInputTree',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    treeType: {
      type: String,
      default: 'treeRadio'
    },
    data: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tagNum: {
      type: Number,
      default: 15
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    size() {
      return this.$store.getters['size'];
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);
      }
    },
    isRadio() {
      return ['treeRadio', 'treeRadioFree'].includes(this.treeType);
    },
    placeholder_() {
      return this.value.length ? '' : this.placeholder;
    },
    jsStyle() {
      return {
        '--paddingLeft': `${this.tagsWidth > 8 ? this.tagsWidth + 10 : 14}px`
      };
    }
  },
  data() {
    return {
      width: 0,
      filterText: '',
      isHover: false,
      reicon: {},
      tagsWidth: '12px',
      tagsList: [],
      hideFilter: false
    };
  },
  watch: {
    filterText: debounce(function (v) {
      this.$refs.tree.$refs.tree.filter(v);
    }, 300)
  },
  methods: {
    ellipsisStr(str = '', length = 4) {
      return ellipsisStr(str, length);
    },
    // 关闭单个标签
    toClose(value) {
      this.$refs.tree.onClose(value);
    },
    // 切换输入框行末图标
    toggleIcon(hover) {
      if (!hover) return this.isHover = false;
      this.tagsList.length && !this.disabled && (this.isHover = true);
    },
    onBlur() {
      this.hideFilter = true;
    },
    onShow() {
      this.$refs.treeTag && (this.width = this.$refs.treeTag.clientWidth);
      this.reicon = {
        transform: 'rotate(180deg)'
      };
    },
    onHide() {
      this.reicon = {};
      if (this.hideFilter) {
        this.filterText = '';
      }
    },
    onCheck(node, {
      checkedKeys
    }) {
      this.isRadio && checkedKeys.includes(node.value) && (this.$refs.popover.showPopper = false);
    },
    onNodeChange(nodes) {
      this.tagsList = nodes.children;
      this.$nextTick(() => this.tagsWidth = this.$refs.tagsBox.clientWidth);
    }
  }
};