import "core-js/modules/es.array.push.js";
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render(h, context) {
    const {
      icon,
      title
    } = context.props;
    const vnodes = [];
    if (icon) {
      if (!icon || icon.indexOf('#') === -1) {
        vnodes.push(h("i", {
          "class": icon
        }));
      }
    }
    if (title) {
      if (!icon || icon.indexOf('#') === -1) {
        vnodes.push(h("span", {
          "slot": 'title'
        }, [title]));
      } else {
        vnodes.push(h("span", {
          "class": "pl10",
          "slot": 'title'
        }, [title]));
      }
    }
    return vnodes;
  }
};