import { dataToFile } from '@/utils/w';
export default {
  name: 'wEnclosure',
  immediate: false,
  props: {
    title: {
      type: String,
      default: '下载'
    },
    enclosure: {
      type: [String, Array, Object],
      defualt: ''
    }
  },
  computed: {
    wSize() {
      return this.$store.getters['size'];
    },
    enclosure_() {
      return dataToFile(this.enclosure);
    }
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    onClick() {
      if (this.enclosure_.length > 1) return this.visible = true;
      const link = document.createElement('a');
      link.setAttribute('download', this.enclosure_[0].name);
      link.setAttribute('target', '_blank');
      link.href = this.enclosure_[0].url;
      link.click();
    }
  }
};