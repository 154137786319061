import { render, staticRenderFns } from "./number.vue?vue&type=template&id=d28ae3a8&scoped=true&"
import script from "./number.vue?vue&type=script&lang=js&"
export * from "./number.vue?vue&type=script&lang=js&"
import style0 from "./number.vue?vue&type=style&index=0&id=d28ae3a8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d28ae3a8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/miles_4fMM/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d28ae3a8')) {
      api.createRecord('d28ae3a8', component.options)
    } else {
      api.reload('d28ae3a8', component.options)
    }
    module.hot.accept("./number.vue?vue&type=template&id=d28ae3a8&scoped=true&", function () {
      api.rerender('d28ae3a8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/w/components/w-table/components/search/components/number.vue"
export default component.exports