var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "operates-box"
  }, [_c("span", {
    ref: "operatesBoxRef"
  }, [_vm.isRecycle ? _c("span", [_c("RecycleGroup", {
    attrs: {
      primaryKey: "id",
      operates: _vm.recycleOperates,
      row: {}
    }
  })], 1) : _vm._l(_vm.data, function (row, $index) {
    return _c("span", {
      key: $index
    }, [_vm._l(_vm.otherOperates, function (item, index) {
      return [_vm.makeOtherOperatesStatus(item.show, row, $index) ? _c("el-button", {
        directives: [{
          name: "p",
          rawName: "v-p",
          value: item.p ? _vm.makeOtherOperatesStatus(item.p, row, $index) : "",
          expression: "item.p ? makeOtherOperatesStatus(item.p, row, $index) : ''"
        }],
        key: index,
        attrs: {
          size: _vm.size
        }
      }, [_vm._v(" " + _vm._s(_vm.makeOtherOperatesStatus(item.title, row, $index)) + " ")]) : _vm._e()];
    }), _c("NormalGroup", {
      attrs: {
        primaryKey: "id",
        row: row,
        $index: $index,
        operates: _vm.normalOperate
      }
    }, [_vm.moreOperate ? _c("span") : _vm._e()]), _c("br")], 2);
  })], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };