var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper",
    class: _vm.classObj
  }, [_c("div", {
    class: {
      "fixed-header": _vm.fixedHeader
    }
  }, [_c("navbar")], 1), _c("div", {
    staticClass: "dp-f align-items-content h100 w-content-main"
  }, [_c("sidebar", {
    staticClass: "sidebar-container"
  }), _c("div", {
    staticClass: "flex-1 main-container",
    class: {
      hasTagsView: _vm.needTagsView
    }
  }, [_vm.needTagsView ? _c("tags-view") : _vm._e(), _c("app-main")], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };